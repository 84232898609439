import { render, staticRenderFns } from "./ProposalProductListSlider.vue?vue&type=template&id=a73d7206"
import script from "./ProposalProductListSlider.vue?vue&type=script&lang=js"
export * from "./ProposalProductListSlider.vue?vue&type=script&lang=js"
import style0 from "./ProposalProductListSlider.vue?vue&type=style&index=0&id=a73d7206&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports