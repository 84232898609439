<template>
  <div class="proposal-productlist-slider">
    <ProductListSlider
      v-for="proposal in proposals"
      :key="proposal.id"
      :proposal="proposal"
      :autoplay="autoplay"
      :paginationClass="paginationClass + proposal.id"
      :cols="cols"
      :sm="sm"
      :md="md"
      :lg="lg"
      :xl="xl || lg"
    >
      <template v-if="!isCardMode(proposal)" #title>
        <div :class="getClass(proposal)" v-if="proposal.descr">
          <h2 class="proposal-title text-uppercase mb-3">
            {{ proposal.descr }}
          </h2>
        </div>
      </template>
      <template v-if="isCardMode(proposal)" #firstCard>
        <ProposalImage :proposal="proposal" class="is-card-mode" />
      </template>
    </ProductListSlider>
  </div>
</template>
<style lang="scss">
.proposal-productlist-slider {
  h3 {
    border-top: 2px solid #fff;
    margin-left: 8px;
    margin-right: 8px;
    text-transform: uppercase;
    font-size: 30px !important;
  }
  .is-card-mode {
    .v-image {
      height: 372px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        height: 228px;
      }
    }
  }
  .slider.product-slider:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
<script>
import ProposalImage from "./ProposalImage.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import has from "lodash/has";
import get from "lodash/get";

export default {
  name: "ProposalProductListSlider",
  components: {
    ProductListSlider,
    ProposalImage
  },
  props: {
    proposals: {
      type: Array,
      required: true
    },
    paginationClass: {
      type: String,
      required: false,
      default: "swiper-pagination"
    },
    autoplay: { type: Boolean, default: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: undefined }
  },
  methods: {
    isCardMode(proposal) {
      return has(proposal, "metaData.category_proposal_type.IMAGE_DESKTOP");
    },
    getClass(proposal) {
      return [
        "proposal-title-wrapper",
        get(proposal, "metaData.category_proposal_type.COLOR")
      ];
    }
  }
};
</script>
